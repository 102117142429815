import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { SideMenuService } from '../services/side-menu.service';
import { timer, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

    // Evento de control de sesion
    private sesionActiva$ = new BehaviorSubject<boolean>(false);

    url = environment.urlServer + 'Login/';
  
    httpOptions = {
      params: new HttpParams().append('', ''),
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  
    private TIME_INTERVAL = 300000;

  constructor(
    private http: HttpClient,
    private router: Router,
    private menuCtrl: MenuController,
    private sideMenuService: SideMenuService,
    private route: ActivatedRoute

  ) { }

  login$(credentials: any) {
    return this.http.post<any>(this.url + 'Autenticar', credentials, this.httpOptions)
      .pipe(map(resp => {
        resp = resp.datos;
        if (resp) {
          sessionStorage.setItem('currentUser', JSON.stringify(resp));
        }
        this.sideMenuService.getMenuOpts();

        this.router.navigate(['/']);
        return resp;
      }));
  }

  crearConectado$(usuario: any) {
    return this.http.post<any>(environment.urlServer + 'Sesiones/CrearConectado', usuario, this.httpOptions);
  }



  public logout() {
    sessionStorage.removeItem('currentUser');
    // window.location.replace('/login');
    this.router.navigate(['/login']);
  }

  public get currentUserValue() {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  getSesionActiva$() {
    return this.sesionActiva$.asObservable();
  }

  

}
