import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SideMenuOpts } from '../interfaces/sistemas/sideMenuOpts';
import { Usuario } from '../interfaces/sistemas/Usuario';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {

  menus: SideMenuOpts[];
  usuario = new Usuario();

  constructor(private httpClient: HttpClient) { }

  public getMenuOpts() {
    let menu;
    if (sessionStorage.getItem('currentUser') && sessionStorage.getItem('currentUser') !== 'null') {
      menu = JSON.parse(sessionStorage.getItem('currentUser')).permisos;
      this.usuario.nombres = JSON.parse(sessionStorage.getItem('currentUser')).nombres;
      this.usuario.apellidos = JSON.parse(sessionStorage.getItem('currentUser')).apellidos;
    } else {
      menu = [];
    }
    this.menus = menu;




    return this.menus;
  }
}
