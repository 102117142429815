export class Usuario {
    id = 0;
    nombres: string;
    apellidos: string;
    // tslint:disable-next-line: variable-name
    id_tipo_identificacion: number;
    // tslint:disable-next-line: variable-name
    nombre_tipo_documento: string;
    identificacion: string;
    // tslint:disable-next-line: variable-name
    id_perfil: number;
    // tslint:disable-next-line: variable-name
    nombre_perfil: string;
    telefono: string;
    celular: string;
    correo: string;
    usuario: string;
    // tslint:disable-next-line: variable-name
    fecha_vencimiento: string;
    estados: string;
    // tslint:disable-next-line: variable-name
    cambio_clave: boolean;
    clave: string;
    // tslint:disable-next-line: variable-name
    nueva_clave: string;
    // tslint:disable-next-line: variable-name
    confirmacion_clave: string;
    // tslint:disable-next-line: variable-name
    usr_busqueda: string;
    // tslint:disable-next-line: variable-name
    id_operador: number;
  }